import React, { ReactNode } from "react";
import Button from "../../reusable/Button";
import "./index.style.css";

interface Props {
  price?: string;
  price1?: string;
  price2?: string;
  price3?: string;
  price4?: string;
  price5?: string;
  price6?: string;
  price7?: string;
  price8?: string;
  price9?: string;
  price10?: string;
  promoPrice?: string;
  promoPrice1?: string;
  promoPrice2?: string;
  promoPrice3?: string;
  promoPrice4?: string;
  promoPrice5?: string;
  promoPrice6?: string;
  promoPrice7?: string;
  promoPrice8?: string;
  promoPrice9?: string;
  promoPrice10?: string;
  title: string | ReactNode;
  time?: string;
  description: string;
  Src: string;
  category?: string;
}

const CardItem: React.FC<Props> = ({
  price,
  price1,
  price2,
  price3,
  price4,
  price5,
  price6,
  promoPrice,
  promoPrice1,
  promoPrice2,
  promoPrice3,
  promoPrice4,
  promoPrice5,
  promoPrice6,
  time,
  title,
  description,
  Src,
  category = "services",
}) => {
  return (
    <section id={category} className="services">
      <div className="services-main">
        <img className="hidden-desktop" src={Src} alt="..." />
        <div className="services-all-text">
          <h1>{title}</h1>
          <p dangerouslySetInnerHTML={{ __html: description }} />
          {price && promoPrice && (
            <h4>
              Prix Promotionnel{" "}
              {price1 && (
                <>
                  <br />
                  <br />
                </>
              )}
              <del>{price}</del> € {time} <br /> <br />
              <span className="promo-price">Offre Janvier {promoPrice} €</span>
            </h4>
          )}
          {price && !promoPrice && (
            <h4>
              Prix Promotionnel{" "}
              {price1 && (
                <>
                  <br />
                  <br />
                </>
              )}
              {price} € {time}
            </h4>
          )}
          {price1 && !promoPrice1 && <h4>{price1} €</h4>}
          {price2 && !promoPrice2 && <h4> {price2} €</h4>}
          {price3 && !promoPrice3 && <h4> {price3} €</h4>}
          {price4 && !promoPrice4 && <h4> {price4} €</h4>}
          {price5 && !promoPrice5 && <h4> {price5} €</h4>}
          {price6 && !promoPrice6 && <h4> {price6} €</h4>}
          {price1 && promoPrice1 && (
            <h4>
              <del>{price1}</del> € <br /> <br />
              <span className="promo-price">Offre Janvier {promoPrice1} €</span>
            </h4>
          )}
          {price2 && promoPrice2 && (
            <h4>
              <del>{price2}</del> € <br /> <br />
              <span className="promo-price">Offre Janvier {promoPrice2} €</span>
            </h4>
          )}{" "}
          {price3 && promoPrice3 && (
            <h4>
              <del>{price3}</del> € <br /> <br />
              <span className="promo-price">Offre Janvier {promoPrice3} €</span>
            </h4>
          )}{" "}
          {price4 && promoPrice4 && (
            <h4>
              <del>{price4}</del> € <br /> <br />
              <span className="promo-price">Offre Janvier {promoPrice4} €</span>
            </h4>
          )}{" "}
          {price5 && promoPrice5 && (
            <h4>
              <del>{price5}</del> € <br /> <br />
              <span className="promo-price">Offre Janvier {promoPrice5} €</span>
            </h4>
          )}{" "}
          {price6 && promoPrice6 && (
            <h4>
              <del>{price6}</del> € <br /> <br />
              <span className="promo-price">Offre Janvier {promoPrice6} €</span>
            </h4>
          )}
          <Button title="Prendre rendez-vous" />
        </div>
        <img className="hidden-mobile" src={Src} alt="..." />
      </div>
    </section>
  );
};

const CardItemReverse: React.FC<Props> = ({
  price,
  price1,
  price2,
  price3,
  price4,
  price5,
  price6,
  price7,
  price8,
  price9,
  price10,
  promoPrice,
  promoPrice1,
  promoPrice2,
  promoPrice3,
  promoPrice4,
  promoPrice5,
  promoPrice6,
  promoPrice7,
  promoPrice8,
  promoPrice9,
  promoPrice10,
  time,
  title,
  description,
  Src,
  category = "services",
}) => {
  return (
    <section id={category} className="services">
      <div className="services-main">
        <img src={Src} alt="..." />
        <div className="services-all-text">
          <h1>{title}</h1>
          <p dangerouslySetInnerHTML={{ __html: description }} />
          {price && promoPrice && (
            <h4>
              Prix Promotionnel{" "}
              {price1 && (
                <>
                  <br />
                  <br />
                </>
              )}
              <del>{price}</del> € {time} <br /> <br />
              <span className="promo-price">Offre Janvier {promoPrice} €</span>
            </h4>
          )}
          {price && !promoPrice && (
            <h4>
              Prix Promotionnel{" "}
              {price1 && (
                <>
                  <br />
                  <br />
                </>
              )}
              {price} € {time}
            </h4>
          )}
          {price1 && !promoPrice1 && <h4>{price1} €</h4>}
          {price2 && !promoPrice2 && <h4> {price2} €</h4>}
          {price3 && !promoPrice3 && <h4> {price3} €</h4>}
          {price4 && !promoPrice4 && <h4> {price4} €</h4>}
          {price5 && !promoPrice5 && <h4> {price5} €</h4>}
          {price6 && !promoPrice6 && <h4> {price6} €</h4>}
          {price7 && !promoPrice7 && <h4> {price7} €</h4>}
          {price8 && !promoPrice8 && <h4> {price8} €</h4>}
          {price9 && !promoPrice9 && <h4> {price9} €</h4>}
          {price10 && !promoPrice10 && <h4> {price10} €</h4>}
          {price1 && promoPrice1 && (
            <h4>
              <del>{price1}</del> € <br /> <br />
              <span className="promo-price">Offre Janvier {promoPrice1} €</span>
            </h4>
          )}
          {price2 && promoPrice2 && (
            <h4>
              <del>{price2}</del> € <br /> <br />
              <span className="promo-price">Offre Janvier {promoPrice2} €</span>
            </h4>
          )}{" "}
          {price3 && promoPrice3 && (
            <h4>
              <del>{price3}</del> € <br /> <br />
              <span className="promo-price">Offre Janvier {promoPrice3} €</span>
            </h4>
          )}{" "}
          {price4 && promoPrice4 && (
            <h4>
              <del>{price4}</del> € <br /> <br />
              <span className="promo-price">Offre Janvier {promoPrice4} €</span>
            </h4>
          )}{" "}
          {price5 && promoPrice5 && (
            <h4>
              <del>{price5}</del> € <br /> <br />
              <span className="promo-price">Offre Janvier {promoPrice5} €</span>
            </h4>
          )}{" "}
          {price6 && promoPrice6 && (
            <h4>
              <del>{price6}</del> € <br /> <br />
              <span className="promo-price">Offre Janvier {promoPrice6} €</span>
            </h4>
          )}
          <Button title="Prendre rendez-vous" />
        </div>
      </div>
    </section>
  );
};

const Cart = () => {
  return (
    <section id="allServices">
      <div className="services-container">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
          <path
            fill="#ffffff"
            fill-opacity="1"
            d="M0,32L80,32C160,32,320,32,480,80C640,128,800,224,960,234.7C1120,245,1280,171,1360,133.3L1440,96L1440,0L1360,0C1280,0,1120,0,960,0C800,0,640,0,480,0C320,0,160,0,80,0L0,0Z"
          ></path>
        </svg>
        <h1 className="services-title-logo">Esthétique Basilix</h1>
        <h1>Soins</h1>
        <a className="services-logo" href="#banner" aria-label="Go to banner" />
      </div>
      <CardItem
        category="face"
        price="130"
        // promoPrice="56"
        // time="(45min)"
        title={"Peeling acide"}
        description={`
    Le Peeling Acide, ou 𝗣𝗲𝗲𝗹𝗶𝗻𝗴 𝗰𝗵𝗶𝗺𝗶𝗾𝘂𝗲 𝗱𝗼𝘂𝘅, est une exfoliation réalisée avec des 𝗮𝗰𝗶𝗱𝗲𝘀 𝗻𝗮𝘁𝘂𝗿𝗲𝗹𝘀 qui éliminent délicatement la couche supérieure de la peau, 
    étant personnalisé en fonction de vos besoins spécifiques.

    Le traitement offre de nombreux bénéfices à votre peau et une 𝗵𝘆𝗱𝗿𝗮𝘁𝗮𝘁𝗶𝗼𝗻 𝗶𝗻𝘁𝗲𝗻𝘀𝗲 qui laisse la peau douce et revitalisée dès la première séance. 
    L'amélioration de la 𝘁𝗲𝘅𝘁𝘂𝗿𝗲 de la peau et la réduction des 𝗶𝗺𝗽𝗲𝗿𝗳𝗲𝗰𝘁𝗶𝗼𝗻𝘀 telles que les 𝘁𝗮𝗰𝗵𝗲𝘀 𝗽𝗶𝗴𝗺𝗲𝗻𝘁𝗮𝗶𝗿𝗲𝘀 et les 𝗰𝗶𝗰𝗮𝘁𝗿𝗶𝗰𝗲𝘀 sont des résultats visibles 
    qui contribuent à un aspect plus uniforme et sain du visage, la peau devenant plus <b>éclatante</b> avec un aspect plus 𝗷𝗲𝘂𝗻𝗲.

   <br/> Contrairement au peeling chimique au 𝘱𝘩𝘦𝘯𝘰𝘭, après avoir effectué l'exfoliation chimique avec des acides naturels de fruits, 
    vous pouvez retourner à votre vie quotidienne normalement, en suivant les instructions du spécialiste.
  `}
        Src="https://firebasestorage.googleapis.com/v0/b/esthetiquebasilix-7e531.appspot.com/o/2025-03-03-14-04-28-274.jpg?alt=media&token=33e15556-84fe-4a91-8cfd-6066a9d8a9cf"
      />

      <CardItemReverse
        price={"Visage - 180"}
        // promoPrice="Visage - 144"
        price1={"Visage + Cou - 220"}
        // promoPrice1={"Visage + Cou - 176"}
        title={"Peeling au Laser"}
        description={
          "Également connu sous le nom de <b>Hollywood Peeling</b>, le peeling laser au charbon actif est une procédure cosmétique non invasive qui consiste à appliquer une fine couche de <b>charbon actif</b> sur la peau, suivie d'une exposition à un <b>laser</b> dermatologique spécial Pico-Laser. Ce traitement présente plusieurs avantages, notamment la <b>fermeture des pores</b> dilatés, l’<b>exfoliation</b> de la couche supérieure de la peau et la stimulation de la production de collagène, laissant la peau plus tonique et rajeunie."
        }
        Src={
          "https://firebasestorage.googleapis.com/v0/b/esthetiquebasilix-7e531.appspot.com/o/peelingaulaser.jpg?alt=media&token=dff31f11-d7f4-4d71-bc78-f851ca3a4d78"
        }
      />

      <CardItem
        price="70"
        // promoPrice="56"
        time="(45min)"
        title={
          <>
            <h1>-ESSENTIEL-</h1>
            <h1>Soin du Visage</h1>
          </>
        }
        description={
          "Le soin du visage ESSENTIEL se concentre sur le nettoyage et la purification de la peau en utilisant des dispositifs de <b>vapeur</b> et des techniques manuelles spécifiques pour l'extraction des points noirs, suivis d'un traitement stimulant au <b>courant galvanique</b>, tonifiant et améliorant la texture de la peau. Ce soin est conçu pour éliminer les impuretés, les cellules mortes, l'excès de sébum et les résidus qui s'accumulent sur la peau, la laissant propre, fraîche et hydratée."
        }
        Src={
          "https://firebasestorage.googleapis.com/v0/b/esthetiquebasilix-7e531.appspot.com/o/esential.jpg?alt=media&token=5fa5c729-8b75-4c1f-a0e8-da8350587d93"
        }
      />
      <CardItemReverse
        price={"90"}
        // promoPrice="72"
        time="(50min)"
        title={
          <>
            <h1>-GOLD-</h1>
            <h1>Soin du Visage avec Hydrafacial Diamant</h1>
          </>
        }
        description={
          "Combine la phase de nettoyage du traitement ESSENTIEL suivie de l'application de principes actifs antioxydants et hydratants, et du traitement de <b>micropeeling</b> à l'aide de la tête diamantée du <b>Hydrafacial Diamant</b>."
        }
        Src={
          "https://firebasestorage.googleapis.com/v0/b/esthetiquebasilix-7e531.appspot.com/o/gold.jpg?alt=media&token=2cb517d4-31b7-497b-ab29-8ee211822dc2"
        }
      />
      <CardItem
        price={"110"}
        // promoPrice="88"
        time="(50min)"
        title={
          <>
            <h1>-DIAMOND-</h1>
            <h1>Soin du Visage avec Hydrafacial Diamant</h1>
          </>
        }
        description={
          "Il reprend les bienfaits des soins ESSENTIEL et GOLD en le personnalisant avec des <b>sérums</b> adaptés aux besoins spécifiques de la peau, suivis de l'application de principes actifs antioxydants et hydratants grâce à la <b>technologie ionique</b> ION (Ionization Optimized Nourishment) et à une séance de <b>photothérapie</b> LED."
        }
        Src={
          "https://firebasestorage.googleapis.com/v0/b/esthetiquebasilix-7e531.appspot.com/o/diamont.jpg?alt=media&token=7763537f-8e54-4e53-85e1-37ac7ed2deac"
        }
      />
      <CardItemReverse
        price={"130"}
        // promoPrice="104"
        time="(50min)"
        title={
          <>
            <h1>-PLATINUM-</h1>
            <h1>Soin du Visage avec Hydrafacial Diamant</h1>
          </>
        }
        description={
          "Il reprend les bienfaits des soins ESSENTIEL, GOLD et DIAMOND et offre des avantages supplémentaires grâce au traitement de <b>radiofréquence</b> à effet rajeunissant et stimulant, suivi d'un traitement de <b>drainage lymphatique</b> par ventouses. Les bienfaits du soin sont visibles dès la première séance, et les résultats continuent de s'améliorer à mesure que le collagène naturel se restaure au fil du temps."
        }
        Src={
          "https://firebasestorage.googleapis.com/v0/b/esthetiquebasilix-7e531.appspot.com/o/platinum.jpg?alt=media&token=0471be6d-f0b2-4a81-8674-7600381e6ba8"
        }
      />

      <CardItem
        price={"120"}
        // promoPrice="96"
        time="(50min)"
        title={"Mésothérapie"}
        description={
          "Le traitement de <b>microneedling</b> associe l'efficacité des sérums riches en peptides, des vitamines et des antioxydants essentiels pour revitaliser l'épiderme, en utilisant un système de micro-aiguilles, tel que le dermapen. Cette technique permet d'optimiser l'absorption des nutriments et de stimuler la régénération cutanée. Les avantages du microneedling incluent la réduction des <b>cicatrices</b> post-acnéiques et des <b>ridules</b>, l'élimination de l'<b>acné</b>, des <b>taches</b> pigmentaires et des <b>pores dilatés</b>, ainsi que le raffermissement et l'éclat de la peau."
        }
        Src={
          "https://firebasestorage.googleapis.com/v0/b/esthetiquebasilix-7e531.appspot.com/o/mesopothamie.jpg?alt=media&token=40b1ba7d-a268-4bde-be9b-f7d836dc9dea"
        }
      />

      <CardItemReverse
        price={"150"}
        // promoPrice="120"
        title={"Dépigmentation des sourcils avec Pico-Laser"}
        description={
          "Pour le <b>détatouage</b> ou la <b>dépigmentation</b> des sourcils, on utilise un laser de dernière génération sans altérer la peau, en agissant sur le pigment avec la capacité de le fragmenter en microparticules, facilitant ainsi leur élimination par le système immunitaire du corps. Ce traitement est efficace pour une gamme variée de couleurs de tatouage et types de peau."
        }
        Src={
          "https://firebasestorage.googleapis.com/v0/b/esthetiquebasilix-7e531.appspot.com/o/picolaser.jpg?alt=media&token=b5ed24a6-92c4-4b8b-a749-72d340171e80"
        }
      />

      <CardItem
        price={"50 - 100"}
        // promoPrice="40 - 80"
        title={"Dépigmentation des taches"}
        description={
          "L'élimination des taches pigmentaires au laser est une procédure cosmétique utilisée pour traiter et réduire l'apparence des taches pigmentaires sur la peau, telles que les taches de <b>vieillesse</b>, les taches de <b>rousseur</b>, le <b>mélasma</b> et les <b>taches</b> solaires. Elle consiste à utiliser un laser spécial pour cibler et détruire les cellules pigmentées excessives de la peau."
        }
        Src={
          "https://firebasestorage.googleapis.com/v0/b/esthetiquebasilix-7e531.appspot.com/o/20240620_074248.jpg?alt=media&token=c4034fc1-c40f-4807-ac8e-d33d1f83ccf7"
        }
      />

      <CardItemReverse
        category="body-sevice"
        price={"Visage complet - 70"}
        price1={"Lévre supérieure - 35"}
        price2={"Menton - 45"}
        price3={"Aisselles - 60"}
        price4={"Maillot - 110"}
        price5={"Jambes entières - 150"}
        price6={"Corps entier - 300"}
        title={"Épilation définitive"}
        description={
          "L’épilation permanente au <b>Laser Diode ICE</b> utilise la technologie de pointe pour éliminer <b>définitivement</b> les poils indésirables en endommageant la racine du poil sans affecter les tissus environnants, empêchant ainsi leur croissance future. Cette méthode est efficace sur différents types de peau, toutes les zones du corps et en toutes saisons. Les autres avantages de l'épilation au Laser Diode ICE sont la <b>précision</b>, l'absence de douleur, la réduction ou l'élimination des poils incarnés et l'amélioration de l'apparence de la peau."
        }
        Src={
          "https://firebasestorage.googleapis.com/v0/b/esthetiquebasilix-7e531.appspot.com/o/epildefinitive.jpg?alt=media&token=30c4cc72-d956-4abf-ab02-1beedabd6849"
        }
      />
      <CardItem
        price={"Visage complet (sourcils non inclus) - 25"}
        price1={"Sourcils (entretien + création) - 18"}
        price2={"Teinture des sourcils - 7"}
        price3={"Lèvre supérieure - 9"}
        price4={"Menton - 9"}
        price5={"Nez - 7"}
        price6={"Aisselles - 20"}
        price7={"Bras - 25"}
        price8={"Bikini complet - 30"}
        price9={"Jambes 1/2 - 25"}
        price10={"Jambes entières - 40"}
        title={"Épilation à la cire"}
        description={
          "L’épilation à la cire est une méthode populaire pour éliminer les poils indésirables de diverses zones du corps, permettant d’obtenir une peau lisse pendant une certaine période."
        }
        Src={
          "https://firebasestorage.googleapis.com/v0/b/esthetiquebasilix-7e531.appspot.com/o/epilare.jpg?alt=media&token=0d2bee27-823d-4e91-9188-71d17555abc7"
        }
      />

      <CardItemReverse
        price={"80 - 150"}
        // promoPrice="64 - 120"
        title={"Élimination au laser des varices fines et de la couperose"}
        description={
          "Le traitement est réalisé avec un <b>laser dermatologique</b> de pointe, offrant des résultats efficaces et un grand confort <b>sans douleur</b>. Les avantages incluent un temps de récupération court ou inexistant et des résultats efficaces."
        }
        Src={
          "https://firebasestorage.googleapis.com/v0/b/esthetiquebasilix-7e531.appspot.com/o/asdasd.jpg?alt=media&token=a6fcff5a-70c5-450a-a5e2-16aac93f45fe"
        }
      />

      <CardItem
        category="massage"
        // price={"85"}
        // time="(60min)"
        title={"Massage thérapeutique"}
        description={
          "Dans notre centre, nous vous proposons une expérience unique de détente et de bien-être grâce à nos services exclusifs de massages thérapeutiques. Conçus pour soulager le stress et la douleur, ils revitalisent votre corps et votre esprit à chaque séance."
        }
        Src={
          "https://firebasestorage.googleapis.com/v0/b/esthetiquebasilix-7e531.appspot.com/o/Massage%20the%CC%81rapeutique.jpg?alt=media&token=302d8057-ff6e-428e-a05b-f036678d9d11"
        }
      />

      {/* <CardItem
        // price={"50"}
        // time="(30min)"
        title={"Réflexologie plantaire"}
        description={
          "Cette technique millénaire repose sur l'application de pressions sur des points spécifiques des pieds, correspondant à différents organes et systèmes du corps. En stimulant ces zones réflexes, nous favorisons une relaxation profonde, améliorons la circulation et renforçons la capacité naturelle du corps à guérir."
        }
        Src={
          "https://firebasestorage.googleapis.com/v0/b/esthetiquebasilix-7e531.appspot.com/o/reflex.jpg?alt=media&token=dcea2b9b-53ce-4b31-8a15-9e930d739d2c"
        }
      />
      <CardItemReverse
        // price={"85"}
        // time="(60min)"
        title={"Drainage lymphatique"}
        description={
          "Découvrez le drainage lymphatique dans notre centre de bien-être. Cette technique douce et rythmée stimule votre système lymphatique, aidant à éliminer les toxines, à réduire les gonflements et à améliorer la circulation. Parfaite pour détoxifier votre corps et renforcer votre bien-être."
        }
        Src={
          "https://firebasestorage.googleapis.com/v0/b/esthetiquebasilix-7e531.appspot.com/o/drenaje-linfatico-1024x930.jpg?alt=media&token=20cbbd35-65e4-4dd2-8bc5-5140e3ca1e44"
        }
      /> */}
      <CardItemReverse
        category="products"
        title={"Nos produits"}
        description={
          "Dans notre centre esthétique, nous sélectionnons soigneusement des produits parmi les meilleures marques du marché, avec des résultats prouvés dans le domaine des soins de la peau, afin de répondre aux besoins uniques de chacune de nos clientes."
        }
        Src={
          "https://firebasestorage.googleapis.com/v0/b/esthetiquebasilix-7e531.appspot.com/o/products.jpg?alt=media&token=0d000602-8188-4186-a2b6-eaaea8cd7d9e"
        }
      />
    </section>
  );
};

export default Cart;
