import React, { useEffect, useState, FormEvent } from "react"; // Import FormEvent
import Button from "../../reusable/Button";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { addMonths } from "date-fns";
import "./index.style.css";
import axios from "axios";
import Swal from "sweetalert2";

interface Reservation {
  name: string;
  surname: string;
  tel: string;
  email: string;
  service: string;
  data: string;
  time: string;
}

const services = [
  "Peeling acide",
  "ESSENTIEL - Soin du Visage",
  "GOLD - Soin du Visage par Hydrafacial Diamant",
  "DIAMOND - Soin du Visage par Hydrafacial Diamant",
  "PLATINUM - Soin du Visage par Hydrafacial Diamant",
  "Mésothérapie",
  "Peeling Laser",
  "Dépigmentation des sourcils et tatouages avec Pico-Laser",
  "Dépigmentation des taches",
  "Épilation définitive",
  "Épilation à la cire",
  "Élimination au laser des varices fines et de la cupérose",
  "Massage thérapeutique",
];
const times = [
  "10h00",
  "11h00",
  "12h00",
  "13h00",
  "14h00",
  "15h00",
  "16h00",
  "17h00",
  "18h00",
];

export default function Form() {
  const [reservedDates, setReservedDates] = useState<Reservation[]>([]);
  const [formData, setFormData] = useState<{
    name: string;
    surname: string;
    tel: string;
    email: string;
    service: string;
    data: string;
    time: string;
  }>({
    name: "",
    surname: "",
    tel: "",
    email: "",
    service: "",
    data: "",
    time: "",
  });
  const [disableBtn, setDisableBtn] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [popupState, setPopupState] = useState<"question" | "denied">(
    "question"
  );

  let isDisabled;

  const getReservedDates = async () => {
    try {
      const response = await axios.get(
        "https://esthetiquebasilixbackend.onrender.com/tasks/get"
      );
      setReservedDates(response.data);
    } catch (error) {
      console.error("Error fetching tasks:", error);
    }
  };

  useEffect(() => {
    getReservedDates();
  }, []);

  useEffect(() => {
    getReservedDates();
  }, [formData.data, formData.service]);

  const handleSubmit = async () => {
    try {
      await axios.post(
        "https://esthetiquebasilixbackend.onrender.com/tasks/save",
        {
          data: formData.data,
          email: formData.email,
          name: formData.name,
          service: formData.service,
          surname: formData.surname,
          tel: formData.tel,
          time: formData.time,
        }
      );
      Swal.fire({
        title: "Inscription réussie !",
        html: `
    <p>Un message de confirmation a été envoyé à l'adresse e-mail indiquée. Si celui-ci tarde à arriver, veuillez vérifier le dossier spam.</p>
    <p>Merci d'avoir choisi notre centre d'esthétique. Un spécialiste sera présent à la date et à l'heure que vous avez choisies pour vous offrir nos services de la meilleure qualité. Cela signifie la préparation des instruments, des produits, etc.</p>
    <p>C'est pourquoi nous vous prions de respecter le rendez-vous que vous avez pris et d'annoncer tout changement au moins 24 heures avant le jour et l'heure fixés.</p>
    <p>Merci. À bientôt !</p>
  `,
        icon: "success",
        confirmButtonText: "OK",
        width: "600px",
        customClass: {
          popup: "custom-alert",
        },
      });

      await axios.get(
        "https://esthetiquebasilixbackend.onrender.com/sentemail",
        {
          params: {
            data: formData.data,
            email: formData.email,
            name: formData.name,
            service: formData.service,
            surname: formData.surname,
            tel: formData.tel,
            time: formData.time,
          },
        }
      );

      setFormData({
        name: "",
        surname: "",
        tel: "",
        email: "",
        service: "",
        data: "",
        time: "",
      });
      getReservedDates();
      console.log("success");
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleFormSubmitAttempt = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (formData.service === "Massage thérapeutique") {
      handleSubmit();
    } else {
      setPopupState("question");
      setShowPopup(true);
    }
  };

  const handlePopupYes = () => {
    setShowPopup(false);
    handleSubmit();
  };

  const handlePopupNo = () => {
    setPopupState("denied");
  };

  const handlePopupClose = () => {
    setShowPopup(false);
  };

  const dataToString = (data: Date | null | string): string => {
    if (!data) return "";
    const date = typeof data === "string" ? new Date(data) : data;
    if (isNaN(date.getTime())) return "";
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const dateString = `${year}-${month}-${day}`;
    return dateString;
  };

  const isWeekday = (date: Date) => {
    const day = date.getDay();
    return day !== 0;
  };

  useEffect(() => {
    const isSpecialEmail =
      formData.email.toLowerCase() === "voicugeorgiana88@gmail.com";
    let hasFutureReservation = false;
    const userReservations: Reservation[] = [];

    reservedDates.forEach((item) => {
      try {
        const itemDate = new Date(item.data);
        const currentDate = new Date();
        itemDate.setHours(0, 0, 0, 0);
        currentDate.setHours(0, 0, 0, 0);

        const isFutureDate = itemDate >= currentDate;

        if (
          isFutureDate &&
          item.email.toLowerCase() === formData.email.toLowerCase()
        ) {
          hasFutureReservation = true;
          userReservations.push(item);
        }
      } catch (e) {
        console.error("Invalid date found in reservedDates:", item.data);
      }
    });

    if (isSpecialEmail) {
      setDisableBtn(false);
    } else if (hasFutureReservation) {
      alert(`Vous avez déjà un rendez-vous pour la date ${
        reservedDates.find((item) => item.email === formData.email)?.data
      }. 
      Si vous souhaitez modifier l'heure de votre rendez-vous, merci de nous contacter au numéro de téléphone ou à l'adresse e-mail indiqués dans la rubrique 'contact' de notre page. 
      Nous vous rappelons que toute non-présentation à la date et à l'heure convenues pourrait entraîner une perte d'accès à nos services à l'avenir. 
      Nous vous remercions de votre compréhension et de votre ponctualité!`);
      setDisableBtn(true);
    } else {
      setDisableBtn(false);
    }
  }, [formData.email, reservedDates]);

  const isTimePast = (
    time: string,
    selectedDateStr: string,
    currentDateStr: string
  ): boolean => {
    if (!selectedDateStr || !time) return false;

    try {
      const [hours] = time.split("h").map(Number);
      if (isNaN(hours)) return false;

      const selectedDate = new Date(selectedDateStr);
      selectedDate.setHours(0, 0, 0, 0);

      const currentDate = new Date(currentDateStr);
      currentDate.setHours(0, 0, 0, 0);

      if (selectedDate.getTime() > currentDate.getTime()) {
        return false;
      }

      if (selectedDate.getTime() === currentDate.getTime()) {
        const now = new Date();
        const currentHour = now.getHours();
        if (hours <= currentHour) {
          return true;
        }
      }

      if (selectedDate.getTime() < currentDate.getTime()) {
        return true;
      }
    } catch (e) {
      console.error("Error parsing date/time in isTimePast:", e);
      return false;
    }

    return false;
  };

  return (
    <section id="form" className="form">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
        <path
          fill="#97afbc"
          fillOpacity="1"
          d="M0,288L60,245.3C120,203,240,117,360,69.3C480,21,600,11,720,16C840,21,960,43,1080,48C1200,53,1320,43,1380,37.3L1440,32L1440,0L1380,0C1320,0,1200,0,1080,0C960,0,840,0,720,0C600,0,480,0,360,0C240,0,120,0,60,0L0,0Z"
        ></path>
      </svg>
      <div className="form-main">
        <div className="form-container">
          <h2>Prendre rendez-vous</h2>
          <form
            onSubmit={handleFormSubmitAttempt}
            id="form-element"
            className="flex flex-col"
          >
            <input
              type="text"
              className="form-control"
              value={formData.name}
              placeholder="Nom"
              onChange={(e) =>
                setFormData({ ...formData, name: e.target.value })
              }
              required
            />
            <input
              type="text"
              className="form-control"
              value={formData.surname}
              placeholder="Prénom"
              onChange={(e) =>
                setFormData({ ...formData, surname: e.target.value })
              }
              required
            />
            <input
              type="tel"
              className="form-control"
              value={formData.tel}
              placeholder="GSM"
              onChange={(e) =>
                setFormData({ ...formData, tel: e.target.value })
              }
              required
              // pattern="[0-9\+\-\s]+"
            />
            <input
              type="email"
              className="form-control"
              value={formData.email}
              placeholder="E-mail  (Saisir correctement votre adresse)"
              onChange={
                (e) =>
                  setFormData({ ...formData, email: e.target.value.trim() }) // Trim whitespace
              }
              required
            />
            <select
              className="form-control"
              value={formData.service}
              onChange={
                (e) =>
                  setFormData({
                    ...formData,
                    service: e.target.value,
                    time: "",
                  }) // Reseteaza ora la schimbarea serviciului
              }
              required={true}
            >
              <option value="">Sélectionner le service</option>
              {services.map((service) => (
                <option
                  key={service}
                  value={service}
                  disabled={service === "Massage thérapeutique"}
                >
                  {service}
                </option>
              ))}
            </select>

            <DatePicker
              selected={formData.data ? new Date(formData.data) : null} // Asigură-te că e obiect Date sau null
              filterDate={isWeekday}
              minDate={new Date()}
              excludeDates={[new Date("2024-08-15")]} // Excludem date specifice
              maxDate={addMonths(new Date(), 2)} // Maxim 2 luni in avans
              onChange={
                (
                  date: Date | null // Tip corect pentru onChange
                ) =>
                  setFormData({
                    ...formData,
                    data: dataToString(date),
                    time: "",
                  }) // Reseteaza ora la schimbarea datei
              }
              className="form-control"
              placeholderText="Date"
              dateFormat="yyyy-MM-dd" // Format explicit
              required
            />

            <select
              className="form-control"
              value={formData.time}
              onChange={(e) =>
                setFormData({ ...formData, time: e.target.value })
              }
              disabled={!formData.data || !formData.service} // Dezactivat dacă nu e selectată data SAU serviciul
              required
            >
              <option value="">Heure</option>
              {times.map((time) => {
                const isReserved = reservedDates.some(
                  (reservation) =>
                    reservation.data === formData.data &&
                    reservation.time === time
                );

                const timeHasPassed = isTimePast(
                  time,
                  formData.data,
                  dataToString(new Date())
                );

                isDisabled = isReserved || timeHasPassed;

                return (
                  <option key={time} value={time} disabled={isDisabled}>
                    {time}
                    {isReserved ? " (Réservé)" : ""}
                    {timeHasPassed ? " (Passé)" : ""}
                  </option>
                );
              })}
            </select>
            <Button
              type="submit"
              title="Prendre rendez-vous"
              disableBtn={disableBtn} // Folosim state-ul disableBtn calculat in useEffect
            />
          </form>
        </div>
        <img
          src="https://firebasestorage.googleapis.com/v0/b/esthetiquebasilix-7e531.appspot.com/o/retoques-t.jpg?alt=media&token=24e2a8f3-6244-4db3-97f9-793feb776846"
          alt="..."
        />
      </div>

      {/* --- POPUP --- */}
      {showPopup && (
        <div className="popup-overlay">
          <div className="popup-content">
            {popupState === "question" ? (
              <>
                <p>
                  Ce service est exclusivement destiné aux femmes.
                  <br /> <br />
                  Êtes-vous une femme ?
                </p>
                <div className="popup-buttons">
                  <button type="button" onClick={handlePopupYes}>
                    Oui
                  </button>
                  <button type="button" onClick={handlePopupNo}>
                    Non
                  </button>
                </div>
              </>
            ) : (
              <>
                <p>
                  Nous sommes désolés ! <br /> Si vous souhaitez acheter une
                  carte cadeau pour une personne chère, vous pouvez passer
                  commande à l'adresse e-mail:
                  <a
                    href={"mailto:Esthetiquebasilix@gmail.com"}
                    className="contact-info-form"
                  >
                    <div className="contact-text">
                      esthetiquebasilix@gmail.com
                    </div>
                  </a>
                  ou directement dans notre centre au Basilix Shopping Center au
                  deuxième étage. Merci de votre compréhension !
                </p>
                <div className="popup-buttons">
                  <button type="button" onClick={handlePopupClose}>
                    Ferme
                  </button>{" "}
                </div>
              </>
            )}
          </div>
        </div>
      )}
      {/* --- END POPUP --- */}
    </section>
  );
}
